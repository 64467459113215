<template>
  <div>
    <!-- <svg id="map-border" xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 1280 640" preserveAspectRatio="none" style="pointer-events: none">
      <path fill="#2c3a4f" d="M-223.5,320c0,0,386.6-78,863.5-78s795,65,863.5,78"></path>
    </svg> -->
    <!-- <div style="overflow:hidden" class="show-for-large-up">
      <iframe  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d721.7510230706547!2d172.4923766!3d-43.6480832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3219c7fc7d8769%3A0x41cf5ff6b0e72baf!2sArarira%20Springs%20Primary!5e0!3m2!1sen!2snz!4v1644304879170!5m2!1sen!2snz" width="2048" height="480" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div style="overflow:hidden" class="show-for-medium-only">
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d721.7510230706547!2d172.4923766!3d-43.6480832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3219c7fc7d8769%3A0x41cf5ff6b0e72baf!2sArarira%20Springs%20Primary!5e0!3m2!1sen!2snz!4v1644304879170!5m2!1sen!2snz" width="1024" height="480" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div style="overflow-hidden" class="show-for-small-only">
      <iframe  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d721.7510230706547!2d172.4923766!3d-43.6480832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3219c7fc7d8769%3A0x41cf5ff6b0e72baf!2sArarira%20Springs%20Primary!5e0!3m2!1sen!2snz!4v1644304879170!5m2!1sen!2snz" width="480" height="480" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div> -->
     <div id="map-1">
       <!-- <a href="http://maps.google.com?q=Ararira+Springs+Primary+School+Russ+Drive+Lincoln+7608+Canterbury+nz" target="_blank">
        <iframe  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyASJ4hU4UCItuHGrJRsZBSl1akscUcmnL8&q=q=Ararira+Springs+Primary+School+Russ+Drive+Lincoln+7608+Canterbury+nz" style="border:0;" allowfullscreen="" loading="lazy" width="100%" height="480px"></iframe>
      </a> -->
    </div>
    <div class="footer-wrap">
      <div class="footer-primary">
        <div class="grid-x align-center">
          <div class="cell medium-10 large-10 contact-info">
            <h1>Get in touch!</h1>
              <p>We'd love to hear from you</p>
              <p><a href="mailto:kiaora@springscommunitychurch.nz">kiaora@springscommunitychurch.nz </a></p>

              <a class="social" href="https://www.facebook.com/springscommunitychurch" target="_blank"><i class="fab fa-facebook-square"></i>           </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//  import 'normalize.css'
export default {
  name: 'Footer'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/_variables.scss";
.footer-wrap {
  padding: 3rem 0 5rem 0;
  background-color: #2c3a4f;
  color: #ffffff;
}
i{
  font-size:2rem;
}

a{
  color: $color-2
}

.social{
  color:#FFF
}
</style>
