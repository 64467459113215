<template>
  <div class="menu-wrap">
    <div class="top-bar">
      <div class="top-bar-left">
        <ul class="dropdown menu">
          <li class="menu-text">
            <a href="/">
              <img
                src="../assets/logos-svg/SCC_Logo_SCC_Horizontal_White.svg"
                style="height: 100px"
              />
            </a>
          </li>
        </ul>
      </div>
      <div class="top-bar-right">
        <ul class="menu align-right">
           <li>
            <a href="\" :class="{ active: currentmenu == 'home' }"> Home </a>
          </li>
          <li>
            <a href="about" :class="{ active: currentmenu == 'about' }">
              What we believe
            </a>
          </li>
        </ul>
      </div>
    </div>

    <!-- <div class="grid-x grid-margin-x">
      <div class="cell medium-6 large-4">12/6/4 cells</div>
      <div class="cell medium-6 large-8">12/6/8 cells</div>
    </div> -->
    <!-- <ul>
      <li>
        <a href="home" :class="{ active: currentmenu == 'home' }"> Home </a>
      </li>
      <li>
        <a href="about" :class="{ active: currentmenu == 'about' }">
          About Us
        </a>
      </li>
    </ul> -->
  </div>
</template>

<script>
//  import 'normalize.css'
export default {
  name: 'Menu',
  props: {
    currentmenu: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
<!--
//,
  // mounted () {
  //   // eslint-disable-next-line
  //   $(this.$el).foundation();
  // }
-->

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/scss/_variables.scss";



.menu-wrap{
  //background-color: $color-5;
  background: rgb(243,122,52);
  background: linear-gradient(7deg, rgba(243,122,52,1) 27%, rgba(237,50,44,1) 100%);
}
.top-bar, .top-bar ul{
    background:none;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
  background-color:transparent;
  
}

a {
  color: #FFF;
  font-size:1.2rem;
  background-color:transparent;
}

@media screen and (max-width: 39.9375em) {
  .menu{
    
  }

}

</style>
